import React from 'react';
import withApollo from 'lib/apollo';
import { Layout } from 'components/Layout/Layout';
import { useUnauthenticatedOnlyRoute } from 'lib/hooks/useUnauthenticatedOnlyRoute';
import LoginPage from 'components/Login';

function Index() {
  useUnauthenticatedOnlyRoute();
  return (
    <Layout title="Login to the Self-Help program SHADE" description="">
      <LoginPage />
    </Layout>
  );
}

export default withApollo(Index, { ssr: false });
