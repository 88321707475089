import React from 'react';
import Icon from 'components/Icon';

import { AuthenticationLayout } from 'components/Layout/AuthenticationLayout';

import { LoginForm } from 'components/Authentication/Forms';

import styles from './Login.module.css';

export function Login() {
  return (
    <AuthenticationLayout
      heading={
        <>
          Log in to <span className="active uppercase">Shade</span>
        </>
      }
    >
      <div className={styles['details']}>
        <Icon id="lock" size="lg" hasBorder backgroundColor="aqua" fill="white" stroke="none" borderColor="none" />
        <p>Enter your details below</p>
      </div>
      <LoginForm />
    </AuthenticationLayout>
  );
}
